var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sale-list" }, [
    _vm.type != 4 && _vm.orderType != 6
      ? _c(
          "div",
          {
            staticClass: "list-item",
            on: {
              click: function ($event) {
                return _vm.applySale(1)
              },
            },
          },
          [_vm._m(0), _vm._m(1)]
        )
      : _vm._e(),
    _vm.getOrderKey(_vm.orderState) !== "DELIVER" &&
    _vm.type != 4 &&
    _vm.orderType != 6
      ? _c(
          "div",
          {
            staticClass: "list-item",
            on: {
              click: function ($event) {
                return _vm.applySale(2)
              },
            },
          },
          [_vm._m(2), _vm._m(3)]
        )
      : _vm._e(),
    _vm.getOrderKey(_vm.orderState) !== "DELIVER" || _vm.orderType == 6
      ? _c(
          "div",
          {
            staticClass: "list-item",
            on: {
              click: function ($event) {
                return _vm.applySale(3)
              },
            },
          },
          [_vm._m(4), _vm._m(5)]
        )
      : _vm._e(),
    _vm.getOrderKey(_vm.orderState) !== "DELIVER"
      ? _c(
          "div",
          {
            staticClass: "list-item",
            on: {
              click: function ($event) {
                return _vm.applySale(4)
              },
            },
          },
          [_vm._m(6), _vm._m(7)]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "item-title" }, [_vm._v("仅退款")]),
      _c("p", { staticClass: "item-content" }, [
        _vm._v("\n        未收到货(包含未签收)，或卖家协商同意前提下\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/ic_arrow_right_gray@2x.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "item-title" }, [_vm._v("退货退款")]),
      _c("p", { staticClass: "item-content" }, [
        _vm._v("\n        未收到货(包含未签收)，或卖家协商同意前提下\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/ic_arrow_right_gray@2x.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "item-title" }, [_vm._v("换货")]),
      _c("p", { staticClass: "item-content" }, [
        _vm._v("\n        已收到货，因质量等问题更换同款\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/ic_arrow_right_gray@2x.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "item-title" }, [_vm._v("补货")]),
      _c("p", { staticClass: "item-content" }, [
        _vm._v("\n        需要补货物\n      "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("img", {
        attrs: { src: require("@/assets/ic_arrow_right_gray@2x.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }