<template>
  <div class="sale-list">
    <div class="list-item" v-if="type!=4 && orderType != 6" @click="applySale(1)">
      <!-- <div v-if="orderType != 6" @click="applySale(1)"> -->
      <div>
        <p class="item-title">仅退款</p>
        <p class="item-content">
          未收到货(包含未签收)，或卖家协商同意前提下
        </p>
      </div>
      <!-- </div> -->
      <!-- <div @click="applySale(3)" else>
        <p class="item-title">换货</p>
        <p class="item-content">
          已收到货，需要更换已收到的货物
        </p>
      </div> -->
      <div class="icon">
        <img src="~@/assets/ic_arrow_right_gray@2x.png" alt="">
      </div>
    </div>
    <div class="list-item" v-if="getOrderKey(orderState) !== 'DELIVER'&&type!=4 && orderType != 6" @click="applySale(2)">
      <div>
        <p class="item-title">退货退款</p>
        <p class="item-content">
          未收到货(包含未签收)，或卖家协商同意前提下
        </p>
      </div>
      <div class="icon">
        <img src="~@/assets/ic_arrow_right_gray@2x.png" alt="">
      </div>
    </div>
    <div class="list-item" v-if="getOrderKey(orderState) !== 'DELIVER' || orderType == 6" @click="applySale(3)">
      <div>
        <p class="item-title">换货</p>
        <p class="item-content">
          已收到货，因质量等问题更换同款
        </p>
      </div>
      <div class="icon">
        <img src="~@/assets/ic_arrow_right_gray@2x.png" alt="">
      </div>
    </div>
    <div class="list-item" v-if="getOrderKey(orderState) !== 'DELIVER'" @click="applySale(4)">
      <div>
        <p class="item-title">补货</p>
        <p class="item-content">
          需要补货物
        </p>
      </div>
      <div class="icon">
        <img src="~@/assets/ic_arrow_right_gray@2x.png" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {orderMixin} from '@/mixins/orderMixin.js'

export default {
  name: "sale-list",
  mixins: [orderMixin],
  data() {
    return {
      orderState: this.$route.query.orderState,    // 待发货时 只有仅退款
      order_id: this.$route.query.order_id,
      type: this.$route.query.shopType,
      orderType: this.$route.query.orderType
    };
  },
  methods: {
    applySale(type) {
      this.$router.replace({
        path: '/apply-sale',
        query: {
          type: type,
          order_id: this.order_id
        }
      })
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.query.type == 1) {
      to.meta.title = "退款申请";
    } else if (to.query.type == 2) {
      to.meta.title = "退货退款申请";
    } else if (to.query.type == 3) {
      to.meta.title = "换货申请";
    }else if (to.query.type == 4) {
      to.meta.title = "补货申请";
    }
    next();
  }
}
</script>
<style scoped lang="less">
.sale-list{
  height:100%;
}
.list-item {
  height: 1.4rem;
  background: #fff;
  border-bottom: 1px solid #E1E1E1;
  padding: 0.3rem 0.24rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .item-title {
    font-size: 0.32rem;
    color: #333333;
    margin-bottom: 5px;
  }

  .item-content {
    font-size: 0.28rem;
    color: #999999;
  }

  .icon {
    height: 0.24rem;
    width: 0.24rem;

    img {
      height: 0.24rem;
      width: 0.24rem;
    }
  }
}
</style>